import axios from "axios";
import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import styled from "styled-components";
import { StyledLabel } from "../Annonce/EtapeOne";
import PhoneInput from "react-phone-input-2";
import { COLORS } from "../../../../COLORS";
import BtnLoader from "../../../Utils/BtnLoader";

type OptionType = {
  value: string;
  label: string;
};
const FormBusiness = () => {
  const [categorie, setCategorie] = useState<OptionType[]>([
    { value: "Auto-Moto", label: "Auto-Moto" },
    { value: "Alimentation", label: "Alimentation" },
    { value: "Immobilier", label: "Immobilier" },
    { value: "Transport", label: "Transport" },
    { value: "Loisirs", label: "Loisirs" },
    { value: "Multimédia", label: "Multimédia" },
    { value: "Divers", label: "Divers" },
  ]);
  const [city, setCity] = useState([
    { value: "St-Georges", label: "St-Georges" },
    { value: "Cayenne", label: "Cayenne" },
    { value: "Matoury", label: "Matoury" },
    { value: "Remire", label: "Remire" },
    { value: "Roura", label: "Roura" },
    { value: "Macouria", label: "Macouria" },
    { value: "Kourou", label: "Kourou" },
    { value: "Sinnamary", label: "Sinnamary" },
    { value: "Iracoubo", label: "Iracoubo" },
    { value: "Mana", label: "Mana" },
    { value: "Awala", label: "Awala" },
    { value: "St-Laurent", label: "St-Laurent" },
    { value: "Apatou", label: "Apatou" },
  ]);
  const [numPhone, setNumPhone] = useState<string | undefined>();
  const [selectedCity, setSelectCity] = useState("");
  const [selectedCat, setSelectedCat] = useState<OptionType | null>(null);
  const [nameBusiness, setNameBusiness] = useState("");
  const [readyToSMS, setReadyToSMS] = useState(false);
  const [codeSMS, setCodeSMS] = useState("");
  const [optionChoose, setOptionChoose] = useState("");
  const [description, setDescription] = useState("");
  const [loadingToSendCode, setLoadingToSendCode] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [statutNumTel, setStatutNumTel] = useState(false);
  const [textBtnSendCode, setTextBtnSendCode] = useState("Envoyer");
  const sendAnnonceBusiness = async () => {
    setLoadingToSendCode(true);
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}business/create`,
        withCredentials: true,
        data: {
          statut: optionChoose === "Particulier" ? 0 : 1,
          pseudo: nameBusiness,
          ville: selectedCity,
          numero: numPhone,
          sms: codeSMS,
          description,
        },
      });
      if (res.data.succes) {
        setOptionChoose("");
        setNameBusiness("");
        setSelectCity("");
        setNumPhone("");
        setCodeSMS("");
        setDescription("");
        toast.success(res.data.succes);
      }
      setLoadingToSendCode(false);
    } catch (error) {
      setLoadingToSendCode(false);
      console.log(error);
      const typedError = error as any;
      if (typedError.response.data.error) {
        return toast.error(typedError.response.data.error);
      }
      toast.error("Une erreur est survenue");
    }
  };

  //checkNumber
  const handleCheckNum = async () => {
    if (!numPhone) {
      return toast.error("Un numéro est requis");
    }
    setLoadingToSendCode(true);
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}business/save/numero`,
        withCredentials: true,
        data: { numero: numPhone },
      });
      if (res) {
        if (res.data.message) {
          setStatutNumTel(true);
          toast.success(res.data.message);
        }
        if (res.data.error) {
          toast.error(res.data.error);
        }
      }
      setLoadingToSendCode(false);
    } catch (error) {
      setLoadingToSendCode(false);
      console.log(error);
      const typedError = error as any;
      if (typedError.response.data) {
        return toast.error(typedError.response.data);
      }
      return toast.error(typedError.response.data.error);
    }
  };

  //check if numero exist
  const checkIfNumerExiste = async () => {
    setLoadingToSendCode(true);
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}business/check/${numPhone}`,
        withCredentials: true,
      });
      if (res) {
        if (res.data.error) {
          toast.info(res.data.error);
          setStatutNumTel(res.data.find);
        }
        if (res.data.ready) {
          console.log(res.data.find);
          setStatutNumTel(res.data.find);
        }
      }
      setLoadingToSendCode(false);
    } catch (error) {
      setLoadingToSendCode(false);
      console.log(error);
      const typedError = error as any;
      return toast.error(typedError.response.data.error);
    }
  };
  useEffect(() => {
    if (!numPhone?.startsWith("594694")) {
      return setReadyToSMS(false);
    }
    if (numPhone?.length === 12) {
      setReadyToSMS(true);
      checkIfNumerExiste();
    } else {
      setReadyToSMS(false);
    }
  }, [numPhone]);
  useEffect(() => {
    if (!statutNumTel) {
      setTextBtnSendCode("Envoyer");
    }
  }, [statutNumTel]);
  return (
    <StyledFormBusiness>
      <div className="box-parent">
        <h1>Votre business</h1>
        {optionChoose && (
          <span
            className="statut-publication"
            onClick={() => setOptionChoose("")}
          >
            Statut : {optionChoose}
          </span>
        )}
        {!optionChoose && (
          <div className="statut-business">
            <button onClick={() => setOptionChoose("Particulier")}>
              Particulier
            </button>
            <button onClick={() => setOptionChoose("Entreprise")}>
              Entreprise
            </button>
          </div>
        )}
        {optionChoose && (
          <div className="rest-form animate__animated animate__fadeInUp">
            {/* <div className="box-cat">
              <CreatableSelect
                isClearable
                options={categorie}
                placeholder="Catégorie ?*"
                className="selected-input"
                onChange={(option) =>
                  setSelectedCat(option as OptionType | null)
                }
                value={selectedCat ? selectedCat : ""}
              />
            </div> */}
            <div className="box-pseudo">
              <input
                type="text"
                value={nameBusiness ? nameBusiness : ""}
                onChange={(e) => setNameBusiness(e.target.value)}
                placeholder={
                  optionChoose === "Particulier"
                    ? "Nom ou titre de votre business*"
                    : "Nom entreprise*"
                }
              />
            </div>
            <div className="box-city-business">
              <span>Vous êtes basé dans quelle ville* : </span>
              <div className="list-ville">
                {city &&
                  city.length > 0 &&
                  city.map((cit, i) => (
                    <StyledLabel
                      key={i}
                      $isSelected={selectedCity == cit.value}
                    >
                      <input
                        type="radio"
                        value={cit.value}
                        checked={selectedCity === cit.value}
                        onChange={() => setSelectCity(cit.value)}
                      />
                      {cit.value}
                    </StyledLabel>
                  ))}
              </div>
            </div>
            <div className="box-numero">
              {readyToSMS && (
                <span className="span-back" onClick={() => setNumPhone("")}>
                  Retour
                </span>
              )}
              {!statutNumTel ? (
                <span>
                  {readyToSMS
                    ? `Un sms sera envoyé sur +${numPhone}`
                    : "Numéro attendu +594 694 xxx xxx"}
                </span>
              ) : (
                <span>Tapez le code que vous avez reçu sur +{numPhone}</span>
              )}
              {!readyToSMS && (
                <PhoneInput
                  country="gf"
                  placeholder="Numéro whatsapp*"
                  onChange={setNumPhone}
                  value={numPhone ? numPhone : ""}
                />
              )}
              {readyToSMS && (
                <div className="checkSMS">
                  {loadingToSendCode ? (
                    <BtnLoader />
                  ) : (
                    !statutNumTel && (
                      <button onClick={() => handleCheckNum()}>
                        {textBtnSendCode}
                      </button>
                    )
                  )}
                  <input
                    type="text"
                    placeholder="Tapez le code"
                    value={codeSMS ? codeSMS : ""}
                    onChange={(e) => setCodeSMS(e.target.value)}
                  />
                </div>
              )}
              {!statutNumTel && (
                <span>Un bouton whatsapp sera généré avec ce numéro</span>
              )}
            </div>
            <div className="box-description">
              {description.length > 200 && (
                <span className="danger-limit">
                  Nombre de caractère dépassé, max 200
                </span>
              )}
              {description.length < 200 && (
                <span>
                  Description* : {description.length} caractère
                  {description.length > 1 ? "s" : ""}
                </span>
              )}
              <textarea
                placeholder="Description de votre business"
                defaultValue={description ? description : ""}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
          </div>
        )}
        {nameBusiness &&
          description &&
          numPhone &&
          selectedCity &&
          (loadingSubmit ? (
            <div className="btn-load">{<BtnLoader />}</div>
          ) : (
            <button
              className="btn-submit"
              onClick={() => sendAnnonceBusiness()}
            >
              Soumettre *
            </button>
          ))}
        {nameBusiness && description && numPhone && selectedCity && (
          <span className="info-submit animate__animated  animate__bounce">
            *En soumettant votre business vous acceptez nos cgu
          </span>
        )}
        {!readyToSMS && (
          <span className="span-info">
            Un code sera envoyé par sms pour la vérification
          </span>
        )}
      </div>
    </StyledFormBusiness>
  );
};

export default FormBusiness;

const StyledFormBusiness = styled.div`
  /* background: pink; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .box-parent {
    display: flex;
    flex-direction: column;
    .span-info {
      display: block;
      margin-top: 20px;
    }
    border-radius: 20px;
    padding: 20px;
    width: 50%;
    background: ${COLORS.blueLow};
    .statut-publication {
      transition: 0.3s;
      text-align: center;
      cursor: pointer;
      color: ${COLORS.greenDark};
      filter: drop-shadow(2px 1px 2px ${COLORS.green});
      margin-bottom: 10px;
      display: block;
    }
    span {
      font-size: 0.8em;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    }
    .statut-business {
      padding: 5px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      margin: 5px 0px;
      button {
        padding: 10px 25px;
        border: none;
        outline: none;
        border-radius: 20px;
        margin: 10px;
        letter-spacing: 0.1em;
        cursor: pointer;
        transition: 0.3s;
        background: ${COLORS.green};
        color: ${COLORS.white};
        font-size: 1.8em;
      }
      button:hover {
        background: ${COLORS.blueLow};
        border: solid 1px ${COLORS.green};
        color: ${COLORS.green};
      }
    }
    .box-pseudo {
      margin-bottom: 10px;
      input {
        width: 50%;
        padding: 5px;
        outline: none;
        border: none;
        border-bottom: solid 1px ${COLORS.green};
        border-radius: 10px;
        font-size: 1.2em;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }
    }
    .box-city-business {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      .list-ville {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
    .box-numero {
      .span-back {
        margin-bottom: 10px;
        color: ${COLORS.danger};
        font-weight: 700;
        cursor: pointer;
      }
      padding: 20px;
      width: 80%;
      background: ${COLORS.purpleLow};
      margin: 10px auto;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      .checkSMS {
        margin-top: 5px;
        button,
        input {
          margin-right: 10px;
          font-family: "Times New Roman", Times, serif;
          padding: 5px 10px;
          outline: none;
          border: none;
          border-radius: 10px;
          cursor: pointer;
          background: ${COLORS.purple};
          border: solid 1px ${COLORS.green};
          color: white;
          font-size: 1.2em;
          transition: 0.3s;
        }
        button:hover {
          background: ${COLORS.green};
        }
      }
    }
    .box-description {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      span {
        margin-bottom: 5px;
      }
      .danger-limit {
        color: ${COLORS.danger};
        font-weight: 700;
      }
      textarea {
        width: 80%;
        padding: 5px;
        outline: none;
        border: none;
        height: 15vh;
        resize: none;
        border-bottom: solid 1px ${COLORS.green};
        border-radius: 10px;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }
    }
    .btn-load {
      display: flex;
      justify-content: center;
      margin: 20px auto;
      width: 20%;
    }
    .btn-submit {
      margin: 10px auto;
      padding: 5px 30px;
      font-size: 2em;
      border: none;
      outline: none;
      border-radius: 10px;
      background: ${COLORS.green};
      color: white;
      cursor: pointer;
      transition: 0.3s;
    }
    .btn-submit:hover {
      border: solid 1px ${COLORS.green};
      background: ${COLORS.purple};
    }
    .info-submit {
      width: 100%;
      text-align: center;
      background: ${COLORS.white};
      padding: 5px;
      font-weight: 700;
      color: ${COLORS.green};
    }
  }
  @media screen and (max-width: 445px) {
    padding: 5px;
    .box-parent {
      width: 100%;
      .box-pseudo {
        input {
          width: 100%;
        }
      }
      .box-numero {
        padding: 20px 10px;
        width: 100%;
        .checkSMS {
          input {
            margin-top: 10px;
          }
        }
      }
      .box-description {
        textarea {
          width: 100%;
        }
      }
    }
  }
`;
