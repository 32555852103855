import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../COLORS";

const Cgu = () => {
  return (
    <StyledCgu>
      <h1>CONDITIONS GÉNÉRALES D'UTILISATION - CGU</h1>

      <section className="first-box">
        <p className="info-important">
          *Les annonces avec numéro autre que Guyane Française sont refusées.
          Numéro attendu pour chaque annonce : +594 694 xxx xxx
        </p>
        <h2>1. Objet des CGU</h2>
        <p>
          Les présentes Conditions Générales d'Utilisation (CGU) définissent les
          modalités d’utilisation du site www.emohup.fr, un site d'annonces pour
          particuliers basé en Guyane Française.
        </p>
        <p className="info-important">
          *Le terme "Annonces" présenté dans ce présent CGU peut faire référence
          aux petites annonces, aux évènements et aux annonces business.
        </p>
        <p className="info-important">
          *Aucun point n'est attribué aux annonces SIMPLES, aux évènements ainsi
          qu'aux annonces BUSINESS
        </p>
      </section>

      <section className="first-box">
        <h2>2. Publication des annonces</h2>
        <p>
          La publication d'une annonce sur Emohup.fr est totalement gratuite.
          Les utilisateurs ne sont pas obligés de s'inscrire, et aucune donnée
          sensible ou confidentielle n'est demandée. Toutefois, chaque annonce
          doit être validée par un administrateur avant sa mise en ligne.
        </p>
        <p>
          Les annonces peuvent être refusées sans explication si elles
          comportent des contenus susceptibles de porter atteinte à autrui ou au
          site. Les annonces de vente d'armes ou d'animaux sont automatiquement
          supprimées. Les annonces jugées commerciales ou suspectes seront
          également rejetées. Chaque annonce a une durée de validité de 45
          jours, après quoi elle sera supprimée.
        </p>
        <p>
          En publiant une annonce vous acceptez que le contenu de votre annonce
          soit utilisé pour la promotion du site www.emohup.fr (hors numéro de
          téléphone )
        </p>
        <p className="info-important">
          *Afin que les AB(Annonces Business) soient reconnues comme actives par
          l'annonceur, elles sont programmées afin d'etre supprimées apres 30
          jours
        </p>
      </section>

      <section className="first-box">
        <h2>3. Sécurité et prévention des fraudes</h2>
        <p>
          Bien que nous nous efforcions de fournir des annonces vérifiées et de
          qualité, nous conseillons aux utilisateurs de rester vigilants face
          aux arnaques et aux tentatives de phishing. Ne procédez à aucun
          virement sans rencontrer physiquement votre interlocuteur. En cas de
          doute, vérifiez le numéro de téléphone ou l'adresse email de la
          personne. Si vous rencontrez un problème, contactez-nous pour signaler
          l'annonce suspecte.
        </p>
      </section>

      <section className="first-box">
        <h2>4.1 Contenu du site</h2>
        <p>
          Ce site a pour objectif de faciliter l’échange d'informations entre
          particuliers en Guyane Française, concernant des biens ou services,
          qu’ils soient ou non en vente. Les contenus du site, qu'ils soient
          textuels, graphiques ou vidéo, sont protégés par le droit d’auteur.
          Toute reproduction ou utilisation commerciale est interdite sans
          l'autorisation expresse de l'éditeur.
        </p>
        <h2>4.2 Publicité et Contenu Sponsorisé</h2>
        <p>
          Certaines parties du site peuvent afficher des annonces
          promotionnelles ou du contenu sponsorisé. Ces contenus sont ajoutés
          directement par notre équipe et ne proviennent pas de services
          publicitaires tiers. Nous nous engageons à garantir leur pertinence et
          leur conformité avec nos valeurs. Toutefois, EmohUp ne peut être tenu
          responsable du contenu des sites tiers vers lesquels ces publicités
          redirigent les utilisateurs. L’accès à ces sites se fait sous la seule
          responsabilité de l’utilisateur.
        </p>
      </section>

      <section className="first-box">
        <h2>5. Données personnelles</h2>
        <p>
          L'éditeur du site s'engage à ce que la collecte et le traitement des
          données personnelles soient conformes au RGPD et à la loi Informatique
          et Libertés. Aucun utilisateur n'est tenu de fournir des informations
          sensibles telles que des coordonnées bancaires ou une adresse postale.
          En cas de doute, contactez-nous directement par email à{" "}
          <strong>dev.frenchlod@gmail.com</strong>.
        </p>
        <p>EmohUp n'utilise aucune donnée a fin des commerciaux</p>
      </section>

      <section className="first-box">
        <h2>6. Récompenses et accumulation de points</h2>
        <p>
          <strong>Emoh Up</strong> propose un programme de récompense destiné
          exclusivement aux particuliers qui publient régulièrement des annonces
          sur le site www.emohup.fr.
        </p>
        <p>
          Chaque annonce validée permet à l'annonceur de cumuler 1 point,
          attribué au numéro de téléphone utilisé lors de la publication de
          l'annonce. Ce point est attribué uniquement si l'annonceur accepte
          explicitement de participer au programme de points. Si l'annonce est
          refusée, aucun point ne sera accordé.
        </p>
        <p>
          Dès 200 points sont accumulés, Emoh Up s'engage à offrir une
          récompense à l'annonceur. La récompense peut prendre la forme d'un
          cadeau ou d'un autre avantage.
        </p>
        <p>
          Après la remise de la récompense, les points sont réinitialisés à 0
          pour le numéro concerné. <strong> Aucun point</strong> ne peut être
          transféré à un autre numéro.
        </p>
        <p className="info-important">
          *Emoh Up se réserve le droit de suspendre ou de modifier les
          conditions du programme de récompenses à tout moment sans préavis. En
          cas d'abus constaté, le numéro concerné peut perdre la totalité de ses
          points.
        </p>
        <p>
          En choisissant de gagner des points vous avez lu et acceptez les
          présentes conditions et avoir pris connaissance des{" "}
          <strong>Infos Récompense</strong>{" "}
        </p>
      </section>

      <section className="first-box">
        <h2>7. Cookies et politique de confidentialité</h2>
        <p>
          Des cookies sont utilisés pour améliorer l'expérience utilisateur et
          fournir des statistiques de visite anonymisées via Google Analytics.
          Les cookies strictement nécessaires au bon fonctionnement du site ne
          peuvent être refusés et ne stockent aucune donnée personnelle.
        </p>
      </section>

      <section className="first-box">
        <h2>8. Liens hypertextes</h2>
        <p>
          Les liens hypertextes présents sur le site ne sont pas sous la
          responsabilité de l'Éditeur. Un utilisateur ne peut pas inclure de
          lien hypertexte dans une annonce sous peine de voir son annonce
          rejetée.
        </p>
      </section>
      <section className="first-box">
        <h2>9. Modification des CGU et autre</h2>
        <p>
          Les présentes Conditions Générales d'Utilisation peuvent être
          modifiées à tout moment par EmohUp, sans préavis. Les utilisateurs
          sont invités à consulter régulièrement les CGU afin de se tenir
          informés des éventuels changements. Toute modification prendra effet
          dès sa publication sur le site www.emohup.fr.
        </p>
      </section>

      <section className="first-box">
        <h2>10. Droit applicable</h2>
        <p>
          Ce site est régi par le droit français. Tout litige relève de la
          compétence des tribunaux français, situés au lieu du siège de
          l’éditeur.
        </p>
      </section>
    </StyledCgu>
  );
};

export default Cgu;
const StyledCgu = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  h1 {
    font-size: 2.5em;
    letter-spacing: 0.4em;
    text-align: center;
  }
  .first-box {
    margin: 10px 0px;
    strong {
      font-size: 1.3em;
      padding: 5px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
      margin: 0px 10px;
    }
    p {
      margin: 20px 0px;
      font-size: 1.1em;
      strong {
        color: ${COLORS.orange};
      }
    }
    h2 {
      margin: 15px 0px;
      font-size: 1.8em;
    }
    .info-important {
      margin: 20px auto;
      background: ${COLORS.blueLow};
      width: 50%;
      padding: 10px;
      color: ${COLORS.danger};
      text-align: center;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
  //width =< 429px
  @media screen and (max-width: 429px) {
    h1 {
      font-size: 1.7em;
      font-weight: normal;
      letter-spacing: 0.2em;
    }
    .first-box {
      .info-important {
        width: 100%;
      }
    }
  }
`;
