import React, { useRef, useState } from "react";
import styled from "styled-components";
import { StyledLabel } from "../Annonce/EtapeOne";
import { COLORS } from "../../../../COLORS";
import { BiSolidImageAdd } from "react-icons/bi";
import BtnLoader from "../../../Utils/BtnLoader";
import { toast } from "react-toastify";
import axios from "axios";
import FormFeedBack from "../FeedBack/FormFeedBack";

const FormEvenement = () => {
  const [city, setCity] = useState([
    { value: "Cayenne", label: "Cayenne" },
    { value: "Matoury", label: "Matoury" },
    { value: "Remire", label: "Remire" },
    { value: "Roura", label: "Roura" },
    { value: "Macouria", label: "Macouria" },
    { value: "St-Georges", label: "St-Georges" },
    { value: "Kourou", label: "Kourou" },
    { value: "Sinnamary", label: "Sinnamary" },
    { value: "Iracoubo", label: "Iracoubo" },
    { value: "Mana", label: "Mana" },
    { value: "Awala", label: "Awala" },
    { value: "St-Laurent", label: "St-Laurent" },
    { value: "Apatou", label: "Apatou" },
  ]);
  const [selectedCity, setSelectCity] = useState("");
  const [pseudo, setPseudo] = useState("");
  const [description, setDescription] = useState("");
  const [info, setInfo] = useState("");
  const [infoForm, setInfoForm] = useState("");
  const [imgSelected, setImgSelected] = useState<string>("");
  const [imgToUpload, setImgToUpload] = useState<File[] | null>([]);
  const [hiden, setHiden] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [load, setLoad] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [messageBox, setMessageBox] = useState(false);
  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.click(); // Déclenche le clic sur l'input caché
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const file: FileList | null = event.target.files[0];
    if (event.target.files) {
      const file: File | null = event.target.files[0];
      // const maxSize = 1826;
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
      if (file) {
        setInfo("");
        if (file.size < maxSize) {
          const img: string | null | undefined | HTMLImageElement = new Image();
          const reader = new FileReader();
          reader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target?.result && typeof e.target.result === "string") {
              img.src = e.target.result; // On assigne le contenu (sous forme d'URL Data) à la source de l'image
            }
          };

          img.onload = () => {
            if (img.width === img.height) {
              const fileUrl = URL.createObjectURL(file);
              setImgToUpload([file]);
              setImgSelected(fileUrl);
            } else {
              setInfo("Image n'est pas carrée 🤧, ratio attendu 1:1");
            }
          };
          //lire img
          reader.readAsDataURL(file);
        }
      } else {
        setInfo("Image trop grande 🤧 ! Max 5 Mo");
      }
    }
  };

  const subEvenement = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!selectedCity || !pseudo) {
      setInfoForm("Ville, champs et images sont obligatoires 🤧");
      return;
    }
    // console.log(imgToUpload);

    if (imgToUpload) {
      if (imgToUpload.length) {
        setLoad(true);
        const data = new FormData();
        data.append("ville", selectedCity);
        data.append("pseudo", pseudo);
        data.append("description", description);
        data.append("hidden", hiden);
        data.append("picture", imgToUpload[0]);
        try {
          const res = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API}evenement/create`,
            withCredentials: true,
            data,
          });

          if (res.data.message) {
            setLoad(false);
            initAllValue();
            setMessageBox(true);
            return toast.success(res.data.message);
          }
          setLoad(false);
        } catch (error) {
          setLoad(false);
          const typedError = error as any;
          console.log(typedError.response.data.message);
          toast.error(typedError.response.data.message);
          setInfo("");
        }
      } else {
        setInfoForm("Image obligatoire 🤧");
        return;
      }
    }
  };

  const initAllValue = () => {
    setSelectCity("");
    setDescription("");
    setPseudo("");
    setImgSelected("");
    setImgToUpload([]);
    setCheckBox(false);
  };
  return (
    <StyledFormEvenement className="animate__animated animate__backInDown">
      {imgSelected && (
        <div className="preview">
          <span>Apperçu de l'image si publiée</span>
          <img src={imgSelected} alt="apperçu image upload" />
        </div>
      )}
      {messageBox && <FormFeedBack setMessageBox={setMessageBox} />}
      <h1>Publication d'un évènement</h1>
      <form onSubmit={(e) => subEvenement(e)}>
        <span>
          Les images(évènements) que nous jugeons susceptibles de nuire à la
          réputation d'Emoh Up peuvent ne pas être publiées*{" "}
        </span>
        {infoForm && <strong className="global-info">{infoForm}</strong>}
        <div className="box-city">
          {city &&
            city.length > 0 &&
            city.map((cit, index) => (
              <StyledLabel key={index} $isSelected={selectedCity === cit.value}>
                <input
                  type="radio"
                  value={cit.value}
                  checked={selectedCity === cit.value}
                  onChange={() => setSelectCity(cit.value)}
                />
                {cit.value}
              </StyledLabel>
            ))}
        </div>
        <div className="space-text">
          <input
            type="text"
            placeholder="Pseudo"
            onChange={(e) => setPseudo(e.target.value)}
            value={pseudo ? pseudo : ""}
          />
          <textarea
            placeholder="Petite description"
            onChange={(e) => setDescription(e.target.value)}
            value={description ? description : ""}
          ></textarea>
        </div>
        <div className="space-img-upload">
          {info && <span>{info}</span>}
          <div className="box-img-upload" onClick={handleIconClick}>
            <span>1 image (ratio 1:1)</span>
            <input
              type="file"
              ref={inputRef}
              accept=".png, .jpg, .jpeg"
              onChange={handleFileChange}
            />
            <BiSolidImageAdd className="icon-add" />
          </div>
        </div>
        <div
          className="acept-info"
          onClick={() => setCheckBox((prev) => !prev)}
        >
          <input
            type="checkbox"
            checked={checkBox ? true : false}
            onChange={() => setCheckBox((prev) => !prev)}
          />
          <span>
            En publiant, vous acceptez nos CGU,politique de confidentialité
          </span>
        </div>
        {checkBox && (
          <div className="space-btn-sub animate__animated animate__bounceInRight">
            <input type="hidden" onChange={(e) => setHiden(e.target.value)} />
            {load ? <BtnLoader /> : <input type="submit" value="Envoyer" />}
          </div>
        )}
      </form>
    </StyledFormEvenement>
  );
};

export default FormEvenement;
const StyledFormEvenement = styled.div`
  padding: 5px;
  margin: 40px auto;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  background: ${COLORS.blueLow};

  form {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 10px auto;
    justify-content: center;
    align-items: center;
    span {
      font-family: "Courier New", Courier, monospace;
      color: ${COLORS.black};
      font-size: 0.8em;
      font-weight: 700;
      text-align: center;
    }
    .global-info {
      color: ${COLORS.danger};
      margin-top: 10px;
    }
    .box-city {
      display: flex;
      justify-content: center;
      align-items: center;
      /* background: pink; */
      flex-wrap: wrap;
    }
    .space-text {
      margin: 10px auto;
      display: flex;
      flex-direction: column;
      /* background: grey; */
      width: 70%;
      padding: 5px;
      input {
        width: 50%;
      }
      textarea {
        height: 10vh;
        resize: none;
      }
      input,
      textarea {
        padding: 5px;
        font-size: 1.2em;
        margin-bottom: 10px;
        border: none;
        outline: none;
        border-radius: 5px;
        border-bottom: solid 2px ${COLORS.green};
      }
    }
    .acept-info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: 10px 0px;
      border: solid 2px ${COLORS.green};
      border-radius: 10px;
      padding: 5px;
      cursor: pointer;
      input {
        pointer-events: none;
      }
    }
    .space-btn-sub {
      /* background: orange; */
      width: 70%;
      margin: 25px auto 0px;
      input {
        cursor: pointer;
        width: 20%;
        background: ${COLORS.green};
        padding: 5px;
        border-radius: 5px;
        outline: none;
        border: none;
        font-size: 1.4em;
        color: ${COLORS.white};
      }
    }
    .space-img-upload {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        color: ${COLORS.danger};
      }
      .box-img-upload {
        width: 50%;
        /* background: pink; */
        padding: 10px;
        border: solid 3px ${COLORS.green};
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 0.9em;
          letter-spacing: 0.1em;
          color: ${COLORS.black};
        }
        input {
          display: none;
        }
        .icon-add {
          font-size: 4em;
          color: ${COLORS.green};
        }
      }
    }
  }
  .preview {
    width: 70%;
    margin: 20px auto;
    border-top: solid 3px ${COLORS.green};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    img {
      margin: 20px auto;
      width: 50%;
    }
  }
  @media screen and (max-width: 442px) {
    form {
      width: 100%;
      .box-city {
      }
      .space-text,
      .space-btn-sub {
        width: 100%;
      }
      .space-btn-sub {
        padding: 0px 5px;
        input {
          width: 30%;
        }
      }
      .space-img-upload {
        width: 90%;
        .box-img-upload {
          width: 70%;
        }
      }
    }
    .preview {
      width: 100%;
      img {
        width: 90%;
      }
    }
  }
`;
