import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../COLORS";
import Annonce from "./Annonce/Annonce";
import axios from "axios";
import PopOneAnnonce from "./PopOneAnnonce";
import FilterAnnonce from "./FilterAnnonces/FilterAnnonce";
import SimpleAnnonce from "./SimpleAnnonces/SimpleAnnonce";
import BtnLoader from "../Utils/BtnLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LilAdsGlobal from "../Ads/LilAdsGlobal";
import ParternesGold from "../Ads/Partenaires/ParternesGold";
import ListVilleUsable from "../Utils/ListVille";
import OneAds from "../Ads/OneAds/OneAds";
import { Dynamic } from "../Context/ContextDynamic";
type TypeAnnonce = {
  categorie: string;
  createdAt: string;
  description: string;
  images?: [string];
  numero: string;
  price: string;
  pseudo: string;
  status: number;
  titre: string;
  type: string;
  updatedAt: string;
  ville: string;
  __v: number;
  _id: string;
};
const Annonces = () => {
  const [annonces, setAnnonces] = useState<TypeAnnonce[]>([]);
  const [oneAnnonce, setOneAnnonce] = useState("");
  const [getCat, setGetCat] = useState("");
  const [annonceSimple, setAnnonceSimple] = useState(false);
  const [loade, setLoade] = useState(true);
  const path = useLocation();
  const nav = useNavigate();
  const { partenaires } = Dynamic();

  const getAllAnnonces = async () => {
    if (!loade) {
      setLoade(true);
    }
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}annonces/all`,
        withCredentials: true,
      });
      if (res.data) {
        setLoade(false);
        setAnnonces(res.data);
      }
    } catch (error) {
      setLoade(false);
      console.log(error);
      return toast.error(
        "Une erreur est survenue lors du chargement des données"
      );
    }
  };

  //with categorie
  const getByCat = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}annonces/filter/${getCat}`,
        withCredentials: true,
      });
      // console.log(res);
      if (res.data) {
        setAnnonces(res.data);
      }
    } catch (error) {
      console.log(error);
      return toast.error(
        "Une erreur est survenue lors du chargement des données"
      );
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0); //pour remonter le scroll
    if (path.pathname === "/simples") {
      setAnnonceSimple(true);
    } else {
      setAnnonceSimple(false);
      if (getCat) {
        getByCat();
      } else {
        if (path.pathname === "/annonces") {
          getAllAnnonces();
        }
      }
    }
  }, [getCat, annonceSimple, path]);
  return (
    <StyledAnnonces>
      {oneAnnonce && (
        <PopOneAnnonce oneAnnonce={oneAnnonce} setOneAnnonce={setOneAnnonce} />
      )}
      <div className="choose-annonce">
        <button
          className={!annonceSimple ? "actif" : ""}
          onClick={() => nav("/annonces")}
        >
          Annonces
        </button>
        <button
          className={annonceSimple ? "simple actif" : "simple"}
          onClick={() => nav("/simples")}
        >
          Simples
        </button>
      </div>
      {partenaires.length > 0 && <OneAds />}
      {!annonceSimple ? (
        <div className="section-annonces-and-publish">
          {/* <LilAdsGlobal /> */}
          <div className="div-filter">
            {/* {loade ? <BtnLoader /> : <FilterAnnonce setGetCat={setGetCat} />} */}
            {loade ? (
              <BtnLoader />
            ) : (
              <ListVilleUsable
                selectedCity={getCat}
                setSelectedCity={setGetCat}
              />
            )}
          </div>
          <div className="just-annonces">
            {annonces && annonces.length > 0 ? (
              annonces
                .sort(
                  (a, b) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
                )
                .map((annonce) => (
                  <Annonce
                    key={annonce._id}
                    id={annonce._id}
                    categorie={annonce.categorie}
                    description={annonce.description}
                    image={annonce.images}
                    numero={annonce.numero}
                    price={annonce.price}
                    pseudo={annonce.pseudo}
                    titre={annonce.titre}
                    type={annonce.type}
                    ville={annonce.ville}
                    setOneAnnonce={setOneAnnonce}
                  />
                ))
            ) : (
              <strong>
                Soyez les premiers a gagner des points en postant une annonce
              </strong>
            )}
          </div>
        </div>
      ) : (
        <SimpleAnnonce />
      )}
    </StyledAnnonces>
  );
};

export default Annonces;

const StyledAnnonces = styled.div`
  background: ${COLORS.white};
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* position: relative; */
  //width =< 429px
  .choose-annonce {
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    button {
      padding: 5px;
      letter-spacing: 0.4em;
      outline: none;
      border-radius: 5px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
      border: solid 2px ${COLORS.green};
      color: ${COLORS.greenDark};
      transition: background-color 0.3s ease;
      cursor: pointer;
    }
    .simple {
      margin-left: 10px;
    }
    .actif {
      background: ${COLORS.green};
      box-shadow: 0px 4px 15px #2df17e4c;
      color: ${COLORS.white};
    }
  }
  .section-annonces-and-publish {
    /* background: blue; */
    width: 100%;
    .div-filter {
      display: flex;
      justify-content: center;
      padding: 5px;
    }
    .just-annonces {
      /* background: pink; */
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width: 429px) {
    margin-top: 50px;
    padding: 0px;
    .choose-annonce {
      margin-top: 30px;
      padding: 5px;
      margin-bottom: 0px;
    }
  }
`;
