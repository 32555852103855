import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../COLORS";
import { useNavigate } from "react-router-dom";
import ListsSimples from "./ListsSimples";
type TypeAnnonce = {
  createdAt: string;
  description: string;
  pseudo: string;
  status: number;
  titre: string;
  updatedAt: string;
  _id: string;
};
const SimpleAnnonce = () => {
  const nav = useNavigate();
  const [annonces, setAnnonces] = useState<TypeAnnonce[]>([]);
  const getAll = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}simple-annonces/all`,
        withCredentials: true,
      });
      if (res.data) {
        setAnnonces(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAll();
  }, []);
  return (
    <StyledSimpleAnnonce>
      {annonces.length > 0 ? (
        annonces
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
          .map((simple, index) => (
            <ListsSimples
              key={index}
              pseudo={simple.pseudo}
              titre={simple.titre}
              description={simple.description}
            />
          ))
      ) : (
        <div className="not-annonce">
          <span>Aucune annonce simple </span>
          <button onClick={() => nav("/post")}>Publier une annonce</button>
        </div>
      )}
    </StyledSimpleAnnonce>
  );
};

export default SimpleAnnonce;
const StyledSimpleAnnonce = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .not-annonce {
    display: flex;
    align-items: center;
    button {
      margin-left: 10px;
      border: none;
      border-radius: 5px;
      padding: 5px;
      background: ${COLORS.green};
      color: ${COLORS.white};
      cursor: pointer;
      letter-spacing: 0.1em;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    }
  }
`;
