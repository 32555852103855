import { createContext, useContext, useEffect, useState } from "react";
import {
  TypeContextDynamic,
  TypeContextDynamicProviderProps,
} from "./TypeContext";
import axios from "axios";
import { toast } from "react-toastify";
type TypePartState = {
  // createdAt: string;
  // updatedAt: string;
  _id: string;
  business: string;
  description: string;
  link: string;
};

const ContextDynamic = createContext<TypeContextDynamic | undefined>(undefined);

export const ContextDynamicProvider = ({
  children,
}: TypeContextDynamicProviderProps) => {
  const [filesUpload, setFilesUpload] = useState<File[] | null>(null);
  const [partenaires, setPartenaires] = useState<TypePartState[]>([]);
  //fecthdataPart
  const fecthDataPart = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}partenaire/all`,
        withCredentials: true,
      });

      if (res.data) {
        setPartenaires(res.data);
      }
    } catch (error) {
      console.log(error);
      return toast.error("une erreur s'est produite");
    }
  };

  useEffect(() => {
    fecthDataPart();
  }, []);
  return (
    <ContextDynamic.Provider
      value={{
        filesUpload,
        setFilesUpload,
        partenaires,
        setPartenaires,
      }}
    >
      {children}
    </ContextDynamic.Provider>
  );
};

export const Dynamic = () => {
  const context = useContext(ContextDynamic);
  if (!context) {
    throw new Error(
      "useContextDynamic must be used within a ContextDynamicProvider"
    );
  }

  return context;
};
