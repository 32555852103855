import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { COLORS } from "./COLORS";
import { Route, Routes } from "react-router-dom";
import Home from "./Components/Home/Home";
import Annonces from "./Components/Annonces/Annonces";
import Evenements from "./Components/Evenements/Evenements";
import Post from "./Components/Post/Post";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Popup from "./Components/PopLegale/Popup";
import NavigationMobile from "./Components/NavigationMobile/NavigationMobile";
import { ToastContainer } from "react-toastify";
import Points from "./Components/Points/Points";
import "animate.css";
import PopUp from "./Components/Utils/PopUp";
import Contact from "./Components/Contacts/Contact";
import AdsHome from "./Components/Ads/AdsHome";
import Locals from "./Components/Local/Locals";

function App() {
  const [compo, setCompo] = useState("");
  const [cookieCheck, setCookieCheck] = useState(false);
  const [adsHome, setAdsHome] = useState(false);
  const checkIfAccept = () => {
    const nameEQ = "acceptEmohup.fr02" + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };
  useEffect(() => {
    if (!checkIfAccept()) {
      setCookieCheck(true);
    }
  }, []);
  return (
    <StyledApp className="App">
      {compo && <Popup setCompo={setCompo} compo={compo} />}
      {/* {!adsHome && <AdsHome setAdsHome={setAdsHome} />} */}
      <Header />
      <Routes>
        <Route path="annonces" element={<Annonces />}></Route>
        <Route path="simples" element={<Annonces />}></Route>
        <Route path="evenements" element={<Evenements />}></Route>
        <Route path="locals" element={<Locals />}></Route>
        <Route path="post" element={<Post />}></Route>
        <Route path="points" element={<Points />}></Route>
        <Route path="contact" element={<Contact />}></Route>
        <Route path="*" element={<Home setCompo={setCompo} />}></Route>
      </Routes>
      <NavigationMobile />
      <Footer setCompo={setCompo} />
      <ToastContainer position="top-right" />
      {cookieCheck && <PopUp setCookieCheck={setCookieCheck} />}
    </StyledApp>
  );
}

export default App;

const StyledApp = styled.div`
  background: ${COLORS.white};
  width: 100%;
  position: relative;
  //width =< 429px
`;
