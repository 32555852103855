import React from "react";
import styled from "styled-components";
import ParternesGold from "../Partenaires/ParternesGold";

const ThreeAds = () => {
  return (
    <StyledThreeAds>
      <ParternesGold />
      <ParternesGold />
      <ParternesGold />
    </StyledThreeAds>
  );
};

export default ThreeAds;
const StyledThreeAds = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
