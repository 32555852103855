import styled from "styled-components";
import ParternesGold from "../Partenaires/ParternesGold";

const OneAds = () => {
  return (
    <StyledOneAds>
      <ParternesGold />
    </StyledOneAds>
  );
};

export default OneAds;
const StyledOneAds = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
