import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../COLORS";
import { FaSquareWebAwesome } from "react-icons/fa6";
import { SlBadge } from "react-icons/sl";
import RecentsAnnonces from "./RecentsAnnonces/RecentsAnnonces";
import RecentsEvenements from "./RecentsEvenements/RecentsEvenements";
import Categories from "./Categories/Categories";
import RecompBasic from "../Points/Basic/RecompBasic";
import LilAdsGlobal from "../Ads/LilAdsGlobal";
import Premium from "../Points/Premium/Premium";
import Gold from "../Points/Gold/Gold";
import ParternesGold from "../Ads/Partenaires/ParternesGold";
import { Dynamic } from "../Context/ContextDynamic";
import ThreeAds from "../Ads/ThreeAds/ThreeAds";
import OneAds from "../Ads/OneAds/OneAds";
import { Fade, JackInTheBox, Slide } from "react-awesome-reveal";

const Home = ({
  setCompo,
}: {
  setCompo: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [compoReturn, setCompoReturn] = useState(0);
  const { partenaires } = Dynamic();
  const displayOneRecomp = () => {
    switch (compoReturn) {
      case 0:
        return <RecompBasic />;
      case 1:
        return <Premium />;
      case 3:
        return <Gold />;
      default:
        return <Gold />;
    }
  };

  const randomCompoToDisplayRecomp = () => {
    const max = 3;
    const value = Math.floor(Math.random() * max);
    setCompoReturn(value);
  };
  useEffect(() => {
    window.scrollTo(0, 0); //pour remonter le scroll
    randomCompoToDisplayRecomp();
  }, []);

  return (
    <StyledHome>
      <Slide direction="right">
        <LilAdsGlobal />
      </Slide>
      <Slide direction="left">
        <div className="to-nav-to-post first-box-up">
          <h2 className="first-up">Emohup uniquement pour la Guyane 😜 </h2>
          <strong> ⭐ Les petites annonces des particuliers</strong>
          <strong> ⭐ Les évènements a venir </strong>
          <strong>⭐ Les business du coin des particuliers/pros</strong>
        </div>
      </Slide>
      <Slide direction="down">
        <div className="banniere-home">
          <h1>Le site d'annonces qui vous récompense</h1>
          <div className="para">
            <SlBadge className="icon-i" />
            <p>
              À chaque annonce validée
              <span>gagnez</span> 1 point
            </p>
          </div>
          <div className="para">
            <FaSquareWebAwesome className="icon-i" />
            <p>
              {" "}
              Soyez le premier à être <span>récompensé</span> dès 200 points
            </p>
          </div>
          <strong className="plus-infos" onClick={() => setCompo("recompense")}>
            En savoir plus
          </strong>
        </div>
      </Slide>
      {partenaires.length > 0 && (
        <Fade triggerOnce>
          <ThreeAds />
        </Fade>
        // <div className="space-partner">
        //   <ParternesGold />
        //   <ParternesGold />
        //   <ParternesGold />
        // </div>
      )}
      <Slide direction="up">
        <div className="to-nav-to-post">
          <p>Faites découvrir votre activité avec Emoh Up !</p>
          <p>
            Particulier ou pro(petite entreprise). Remplissez le formulaire
            "BUSINESS"
          </p>
          <Link to="/post" className="the-a a_anime">
            Je publie mon business
          </Link>
        </div>
      </Slide>
      <Slide direction="left" triggerOnce>
        <div className="banniere-recompense">
          <div className="text-recompense">
            <strong>Récompenses pour les publications d'annonces</strong>
          </div>
          {displayOneRecomp()}
          <Link to="/points" className="the-a">
            Voir plus
          </Link>
        </div>
      </Slide>
      <LilAdsGlobal />
      <Slide direction="right">
        <div className="to-nav-to-post">
          <h2>Nouveau ! Publier désormais vos évènements*</h2>
          <Link to="/post" className="the-a a_anime">
            Publier un évènement
          </Link>
        </div>
      </Slide>
      {partenaires.length > 0 && <OneAds />}
      <JackInTheBox>
        <div className="img-en-avant">
          <img src="./items/home/@emohup.png" alt="img-avant" />
        </div>
      </JackInTheBox>
      {/* <Categories /> */}
      {/* <RecentsAnnonces />
      <RecentsEvenements /> */}
    </StyledHome>
  );
};

export default Home;
const StyledHome = styled.section`
  /* background: ${COLORS.blueLow}; */
  border-radius: 10px;
  padding: 10px;
  margin: 20px auto;
  width: 90%;
  .first-box-up {
    background: ${COLORS.green} !important;
    border-radius: 35px !important;
  }
  .banniere-home {
    background: ${COLORS.blueLow};
    background-image: url("./items/kourou.jpg");
    /* background-image: url("./items/bannierde.jpg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-size: auto; */
    border-radius: 25px;
    padding: 10px;
    margin: 25px 0px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    h1 {
      text-align: center;
      font-size: 3.7em;
      margin-bottom: 10px;
      letter-spacing: 0.4em;
      text-transform: uppercase;
      color: ${COLORS.gold};
      filter: drop-shadow(5px 5px 5px ${COLORS.black});
    }
    .para {
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-i {
        margin-right: 5px;
        font-size: 4em;
        color: ${COLORS.gold};
      }
      p {
        text-align: center;
        font-size: 2em;
        color: ${COLORS.white};
        margin-top: 10px;
        filter: drop-shadow(2px 2px 2px ${COLORS.black});
        span {
          filter: drop-shadow(2px 2px 2px ${COLORS.black});
          margin: 0px 10px;
          letter-spacing: 0.1em;
          color: ${COLORS.green};
        }
      }
    }
    .plus-infos {
      display: block;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
      color: ${COLORS.white};
      background: ${COLORS.green};
      width: 10%;
      margin: 10px auto;
      border-radius: 5px;
      padding: 5px;
      /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4); */
      transition: background-color 0.3s ease, color 0.3s ease;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4), 0px 0px 15px ${COLORS.green};
    }
    .plus-infos:hover {
      background: ${COLORS.greenDark};
    }
  }
  .to-nav-to-post,
  .banniere-recompense {
    width: 70%;
    background: ${COLORS.purple};
    /* background-image: url("./items/lilbannier.jpg"); */
    background-position: center;
    margin: 30px auto;
    padding: 10px;
    display: flex;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    p {
      font-size: 1.3em;
      margin-top: 10px;
      text-align: center;
      font-family: "Times New Roman", Times, serif;
    }
    .first-up {
      font-size: 2.3em;
      color: ${COLORS.gold};
    }
    strong {
      color: ${COLORS.white};
      font-size: 1.5em;
    }
    .text-recompense {
      position: relative;
      overflow: hidden;
      width: 100%; /* Ajustez la largeur selon votre besoin */
      height: 50px;
      strong {
        font-size: 1.7em;
        margin-bottom: 10px;
        display: inline-block;
        white-space: nowrap;
        animation: scroll 20s linear infinite;
        padding-right: 100%;
        color: black;
      }
    }
    h2 {
      color: ${COLORS.black};
    }
    .the-a {
      margin-top: 15px;
      padding: 10px;
      text-decoration: none;
      font-size: 1.4em;
      /* background: ${COLORS.purple}; */
      background: ${COLORS.green};
      border-radius: 5px;
      /* color: ${COLORS.white}; */
      color: ${COLORS.white};
      //box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
      transition: background-color 0.3s ease, color 0.3s ease;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4), 0px 0px 15px ${COLORS.green};
    }
    .the-a:hover {
      background: ${COLORS.greenDark};
    }
    .the-a:visited {
      text-decoration: none;
      color: none;
    }
  }
  .banniere-recompense {
    width: 100% !important;
    background: ${COLORS.blueLow} !important;
  }

  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-60%);
    }
  }

  .bouncing-text {
    display: inline-block;
  }

  .img-en-avant {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    img {
      width: 30%;
    }
  }

  //width =< 429px
  @media screen and (max-width: 445px) {
    margin-top: 50px;
    padding-top: 30px;
    width: 100%;
    .banniere-home {
      background-size: cover;
      h1 {
        font-size: 1.5em;
      }
      .para {
        flex-direction: column;
        .icon-i {
          margin-right: 10px;
        }
        p {
          /* background: pink; */
          width: 100%;
          font-size: 1.35em;
          margin-bottom: 10px;
        }
      }
      .plus-infos {
        width: 30%;
      }
    }
    .banniere-recompense {
      .text-recompense {
        strong {
          animation: scroll 25s linear infinite;
        }
      }
    }
    .to-nav-to-post {
      width: 100%;
      .first-up {
        font-size: 1.8em;
      }
      strong {
        font-size: 1.1em;
      }
      h2 {
        text-align: center;
      }
    }
    .img-en-avant {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      img {
        width: 80%;
      }
    }
  }
`;
