import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GoHomeFill } from "react-icons/go";
import { TbLayoutListFilled } from "react-icons/tb";
import { BiSolidCalendarAlt } from "react-icons/bi";
import { MdCloudUpload } from "react-icons/md";
import { BsPatchQuestionFill } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { SiTarget } from "react-icons/si";
import { COLORS } from "../../COLORS";
import { MdLinearScale } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

const NavigationMobile = () => {
  const [plus, setPlus] = useState(false);
  const nav = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (plus) {
      setPlus(false);
    }
  }, [nav]);
  return (
    <StyledNavigationMobile $css={plus}>
      <ol>
        <li onClick={() => nav("/")}>
          <GoHomeFill
            className={`icon-nav ${location.pathname === "/" && "activ"}`}
          />
          <span>Accueil</span>
        </li>
        <li onClick={() => nav("/annonces")}>
          <TbLayoutListFilled
            className={`icon-nav ${
              location.pathname === "/annonces" ||
              location.pathname === "/simples"
                ? "activ"
                : ""
            }`}
          />
          <span>Annonces</span>
        </li>
        <li onClick={() => nav("/evenements")}>
          <BiSolidCalendarAlt
            className={`icon-nav ${
              location.pathname === "/evenements" && "activ"
            }`}
          />
          <span>Evénement</span>
        </li>
        <li onClick={() => nav("/locals")}>
          <SiTarget
            className={`icon-nav local ${
              location.pathname === "/locals" && "activ"
            }`}
          />
          <span>Local</span>
        </li>
        <li onClick={() => setPlus((prev) => !prev)}>
          {plus ? (
            <MdLinearScale className="icon-nav other" />
          ) : (
            <FaPlus className="icon-nav other" />
          )}
          <span>{plus ? "Moins" : "Plus"}</span>
        </li>
        {plus && (
          <>
            <li onClick={() => nav("/points")}>
              <BsPatchQuestionFill
                className={`icon-nav ${
                  location.pathname === "/points" && "activ"
                }`}
              />
              <span>Points</span>
            </li>
            <li onClick={() => nav("/post")}>
              <MdCloudUpload
                className={`icon-nav ${
                  location.pathname === "/post" && "activ"
                }`}
              />
              <span>Publier</span>
            </li>
          </>
        )}
      </ol>
    </StyledNavigationMobile>
  );
};

export default NavigationMobile;
const StyledNavigationMobile = styled.nav<{ $css: boolean }>`
  display: none;
  transition: 0.3s;
  @media screen and (max-width: 442px) {
    display: block;
    /* justify-content: ${({ $css }) =>
      $css ? "flex-start" : "space-evenly"}; */
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: ${COLORS.purple};
    padding: 10px 10px 30px 10px;
    transition: 0.3s;
    height: ${({ $css }) => ($css ? "20vh" : "11vh")};
    ol {
      display: flex;
      justify-content: space-around;
      flex-wrap: ${({ $css }) => ($css ? "wrap" : "nowrap")};
      /* justify-content: ${({ $css }) =>
        $css ? "flex-start" : "space-around"}; */
      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 75px;
        margin: ${({ $css }) => ($css ? "5px" : "0px")};
        .icon-nav {
          font-size: 2em;
          color: ${COLORS.white};
        }
        .other {
          color: #fcff73c4;
        }
        .activ {
          color: ${COLORS.green};
        }
        span {
          font-weight: 100;
          font-size: 0.8em;
        }
      }
    }
  }
`;
