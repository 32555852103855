import React from "react";
import styled from "styled-components";
import ParternesGold from "../Partenaires/ParternesGold";

const TwoAds = () => {
  return (
    <StyledTwoAds>
      <ParternesGold />
      <ParternesGold />
    </StyledTwoAds>
  );
};

export default TwoAds;

const StyledTwoAds = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* justify-content: center;
  align-items: center; */
`;
