import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ListVilleUsable from "../Utils/ListVille";
import { toast } from "react-toastify";
import axios from "axios";
import LocalBusiness from "./LocalBusiness";
import { COLORS } from "../../COLORS";
type TypeBusinessA = {
  createdAt: string;
  description: string;
  pseudo: string;
  numero: string;
  statut: number;
  updatedAt: string;
  ville: string;
  _id: string;
};
const Locals = () => {
  const [selectedVille, setSelectedVille] = useState("");
  const [annonceBusiness, setAnnonceBusiness] = useState<TypeBusinessA[]>([]);
  const getAllActif = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}business/all`,
        withCredentials: true,
      });
      if (res.data) {
        setAnnonceBusiness(res.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue");
    }
  };
  const getAllWithFiltre = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}business/all/${selectedVille}`,
        withCredentials: true,
      });
      console.log(res);
      if (res.data) {
        setAnnonceBusiness(res.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue");
    }
  };
  useEffect(() => {
    if (selectedVille) {
      getAllWithFiltre();
    } else {
      getAllActif();
    }
  }, [selectedVille]);
  return (
    <StyledLocals>
      <div className="filter-business-annonce">
        <ListVilleUsable
          selectedCity={selectedVille}
          setSelectedCity={setSelectedVille}
        />
      </div>
      <span className="b-du-coin">Les business du coin : </span>
      <div className="box-annonce-business">
        {annonceBusiness.length > 0 ? (
          annonceBusiness
            .sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            )
            .map((business, index) => (
              <LocalBusiness
                key={index}
                createdAt={business.createdAt}
                description={business.description}
                pseudo={business.pseudo}
                numero={business.numero}
                statut={business.statut}
                ville={business.ville}
                id={business._id}
              />
            ))
        ) : (
          <span className="not-a-b">Aucun</span>
        )}
      </div>
    </StyledLocals>
  );
};

export default Locals;

const StyledLocals = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .b-du-coin {
    width: 100%;
    padding: 0px 10px;
    text-align: left;
  }
  .filter-business-annonce {
    width: 80%;
    padding: 10px;
    display: flex;
    justify-content: center;
  }
  .box-annonce-business {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    .not-a-b {
      width: 100%;
      background: ${COLORS.purpleLow};
      padding: 10px;
      text-align: center;
    }
  }
  @media screen and (max-width: 445px) {
    margin-top: 50px;
    padding-top: 30px;
    .filter-business-annonce {
      width: 100%;
    }
  }
`;
