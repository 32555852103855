import React, { useState } from "react";
import styled from "styled-components";
import CreatableSelect from "react-select/creatable";
import { COLORS } from "../../../../COLORS";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MdVolunteerActivism } from "react-icons/md";
import { MdOutlineVolunteerActivism } from "react-icons/md";

type OptionType = {
  value: string;
  label: string;
};
type TypeProps = {
  pseudo: string;
  setPseudo: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCity: React.Dispatch<React.SetStateAction<OptionType | null>>;
  selectedCity: OptionType | null;
  selectedCategory: OptionType | null;
  setSelectedCategory: React.Dispatch<React.SetStateAction<OptionType | null>>;
  setSelectedType: React.Dispatch<React.SetStateAction<string>>;
  selectedType: string;
  numPhone: string | undefined;
  setNumPhone: React.Dispatch<React.SetStateAction<string | undefined>>;
  gainPoint: boolean;
  setGainPoint: React.Dispatch<React.SetStateAction<boolean>>;
};
const EtapeOne = ({
  pseudo,
  setPseudo,
  setSelectedCity,
  selectedCity,
  selectedCategory,
  setSelectedCategory,
  setSelectedType,
  selectedType,
  numPhone,
  setNumPhone,
  gainPoint,
  setGainPoint,
}: TypeProps) => {
  const [city, setCity] = useState([
    { value: "Cayenne", label: "Cayenne" },
    { value: "Matoury", label: "Matoury" },
    { value: "Remire", label: "Remire" },
    { value: "Roura", label: "Roura" },
    { value: "Macouria", label: "Macouria" },
    { value: "St-Georges", label: "St-Georges" },
    { value: "Kourou", label: "Kourou" },
    { value: "Sinnamary", label: "Sinnamary" },
    { value: "Iracoubo", label: "Iracoubo" },
    { value: "Mana", label: "Mana" },
    { value: "Awala", label: "Awala" },
    { value: "St-Laurent", label: "St-Laurent" },
    { value: "Apatou", label: "Apatou" },
  ]);
  const [categorie, setCategorie] = useState<OptionType[]>([
    { value: "Auto-Moto", label: "Auto-Moto" },
    { value: "Immobilier", label: "Immobilier" },
    { value: "Maison", label: "Maison" },
    { value: "Loisirs", label: "Loisirs" },
    { value: "Multimédia", label: "Multimédia" },
    { value: "Services", label: "Services" },
    { value: "Divers", label: "Divers" },
  ]);
  const [type, setType] = useState([
    {
      label: "Vente",
      value: "Vente",
    },
    {
      label: "Location",
      value: "Location",
    },
    {
      label: "Demande",
      value: "Demande",
    },
    {
      label: "Offre",
      value: "Offre",
    },
  ]);
  return (
    <StyledEtapeOne className="animate__animated animate__bounceInRight">
      <input
        type="text"
        // value={pseudo ? pseudo : ""}
        defaultValue={pseudo ? pseudo : ""}
        placeholder="Pseudo*"
        className="input-pseudo"
        onChange={(e) => setPseudo(e.target.value)}
      />
      <CreatableSelect
        isClearable
        options={city}
        placeholder="Ville ?*"
        className="selected-input"
        onChange={(option) => setSelectedCity(option as OptionType | null)}
        value={selectedCity}
      />
      <CreatableSelect
        isClearable
        options={categorie}
        placeholder="Catégorie ?*"
        className="selected-input"
        onChange={(option) => setSelectedCategory(option as OptionType | null)}
        value={selectedCategory ? selectedCategory : ""}
      />
      <div className="type-annonce">
        {type.map((cat, index) => (
          <StyledLabel
            key={index}
            $isSelected={selectedType === cat.value} // Passe la sélection comme prop
          >
            <input
              type="radio"
              value={cat.value}
              checked={selectedType === cat.value}
              onChange={() => setSelectedType(cat.value)}
            />
            {cat.label}
          </StyledLabel>
        ))}
      </div>
      <div className="number">
        <span className="libel-num">Numéro whatsapp (694 xxx xxx)*</span>
        <PhoneInput
          country="gf"
          placeholder="Numéro whatsapp*"
          onChange={setNumPhone}
          value={numPhone ? numPhone : ""}
        />
        <div
          className="activ-num"
          onClick={() => setGainPoint((prev) => !prev)}
        >
          {/* <input
            type="checkbox"
            checked={gainPoint ? true : false}
            onChange={() => setGainPoint((prev) => !prev)}
          /> */}
          <div className="text-detail">
            {gainPoint ? (
              <MdVolunteerActivism className="i-gain" />
            ) : (
              <MdOutlineVolunteerActivism className="i-gain" />
            )}
            <span>{gainPoint ? "1 point**" : "0 point"}</span>
          </div>
        </div>
      </div>
    </StyledEtapeOne>
  );
};

export default EtapeOne;
const StyledEtapeOne = styled.div`
  /* background: orange; */
  background: ${COLORS.blueLow};
  width: 60%;
  .input-pseudo {
    width: 50%;
    font-size: 1.3em;
    padding: 5px;
    outline: none;
    border: none;
    margin-bottom: 10px;
    border-radius: 3px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
  }
  .selected-input {
    margin-bottom: 10px;
  }
  .type-annonce {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 10px;
  }
  .number {
    /* background: red; */
    .libel-num {
      font-size: 0.7em;
    }
    .activ-num {
      width: 50%;
      padding: 5px;
      margin: 10px auto 0px;
      background: ${COLORS.purple};
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
      input {
        margin-right: 5px;
        cursor: pointer;
      }
      .text-detail {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .i-gain {
          font-size: 1.7em;
          color: ${COLORS.gold};
        }
        span {
          font-size: 1em;
        }
      }
    }
  }

  @media screen and (max-width: 429px) {
    width: 80%;
  }
`;

// Style pour le label
export const StyledLabel = styled.label<{ $isSelected: boolean }>`
  display: inline-block;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  background-color: ${({ $isSelected }) =>
    $isSelected ? COLORS.green : COLORS.white};
  color: ${({ $isSelected }) => ($isSelected ? COLORS.white : COLORS.black)};
  border: 1px solid
    ${({ $isSelected }) => ($isSelected ? COLORS.green : COLORS.white)};
  transition: 0.3s ease, color 0.3s ease;
  font-size: ${({ $isSelected }) => ($isSelected ? "1.5em" : "1.1em")};

  input {
    display: none;
  }

  &:hover {
    /* background: ${({ $isSelected }) =>
      $isSelected ? COLORS.white : COLORS.green}; */
    background: ${COLORS.green};
    color: ${COLORS.white};
  }
`;
