import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../COLORS";
import { toast } from "react-toastify";
import { Dynamic } from "../../Context/ContextDynamic";
import axios from "axios";

const ParternesGold = () => {
  const el = useRef(null);
  const hasRun = useRef(false);
  const [selectedPartner, setSelectedPartner] = useState<any | null>(null);

  const { partenaires } = Dynamic();
  const selectRandomPartner = (list: any[]) => {
    if (list.length > 0) {
      const randomIndex = Math.floor(Math.random() * list.length);
      setSelectedPartner(list[randomIndex]); // Sauvegarde le partenaire sélectionné
    }
  };

  const goPart = () => {
    countClick(selectedPartner._id);
    if (selectedPartner.link) {
      window.open(selectedPartner.link, "_blank");
    }
  };

  const observationEl = () => {
    if (!el.current) return; // Vérifie que l'élément est bien présent
    // Création de l'instance d'IntersectionObserver
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Vérifie si l'élément est visible dans le viewport
        if (entry.isIntersecting && selectedPartner) {
          //id to view count
          countView(selectedPartner._id);
        }
      },
      { threshold: 0.5 } // Déclenche l'observation lorsque 50% de l'élément est visible
    );

    // Démarrer l'observation si l'élément est présent
    if (el.current) {
      observer.observe(el.current);
    }

    // Nettoyage : arrêter l'observation lors du démontage du composant
    return () => {
      if (el.current) {
        observer.unobserve(el.current);
      }
    };
  };
  const countClick = async (id: string) => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}partenaire/check/${id}`,
        withCredentials: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const countView = async (id: string) => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}partenaire/view/${id}`,
        withCredentials: true,
      });
    } catch (error) {
      console.log(error);
      return toast.error("une erreur s'est produite");
    }
  };

  useEffect(() => {
    if (partenaires.length > 0 && !hasRun.current) {
      selectRandomPartner(partenaires);
      hasRun.current = true;
    }
  }, [partenaires]);

  useEffect(() => {
    if (selectedPartner) {
      observationEl();
    }
  }, [selectedPartner]);
  if (!selectedPartner) return null;
  return (
    <StyledParternesGold onClick={() => goPart()} ref={el}>
      <span className="i-indic">Annonce sponsorisée</span>
      <div className="box-title">
        <h2>{selectedPartner.business}</h2>
      </div>
      <p>{selectedPartner.description}</p>
    </StyledParternesGold>
  );
};

export default ParternesGold;

const StyledParternesGold = styled.div`
  /* width: 25%; */
  width: 30%;
  margin: 0px auto;
  height: 15vh;
  background: white;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  box-shadow: 1px 1px 10px ${COLORS.green};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .i-indic {
    box-shadow: 2px -3px 10px ${COLORS.green};
    font-family: "Times New Roman", Times, serif;
    color: #c4c4c4;
    position: absolute;
    top: -10px !important;
    right: 0px;
    font-size: 0.8em;
    padding: 5px 5px 0px 10px;
    color: ${COLORS.green};
    background: white;
    border-radius: 5px;
  }
  .box-title {
    padding: 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 100%;
    border-right: solid 1px ${COLORS.green};
    h2 {
      text-align: center;
      font-size: 1em;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      color: ${COLORS.green};
    }
  }
  p {
    font-size: 0.8em;
    text-align: center;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: black;
  }

  @media screen and (max-width: 445px) {
    width: 90%;
    height: 10vh;
    margin: 15px auto;
    .i-indic {
      top: 0px;
    }
    .box-detail-partenaire {
      .box-img-title {
        img {
          width: 50%;
        }
      }
    }
  }
`;
