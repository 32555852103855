import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../COLORS";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoGitCommitSharp, IoLocationOutline } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import Slider from "react-slick";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaPhoneFlip } from "react-icons/fa6";
import { MdImageNotSupported } from "react-icons/md";
import { MdOutlineNavigateNext } from "react-icons/md";
import { MdOutlineNavigateBefore } from "react-icons/md";
import BtnLoader from "../Utils/BtnLoader";
import { Dynamic } from "../Context/ContextDynamic";
import TwoAds from "../Ads/TwoAds/TwoAds";
type TypeProps = {
  oneAnnonce: string;
  setOneAnnonce: React.Dispatch<React.SetStateAction<string>>;
};

type TypeState = {
  categorie: string;
  createdAt: string;
  description: string;
  images: [string];
  numero: string;
  price: string;
  titre: string;
  type: string;
  ville: string;
  pseudo: string;
  point: string;
};

const PopOneAnnonce = ({ oneAnnonce, setOneAnnonce }: TypeProps) => {
  const [annonce, setAnnonce] = useState<TypeState[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [search, setSearch] = useState(true);
  const { partenaires } = Dynamic();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const vertDate = (value: string) => {
    const date = new Date(value);
    const now = new Date();

    // On définit aujourd'hui et hier en retirant le temps (heure, minute, seconde)
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Vérification de la date
    if (date >= today) {
      return "Publiée aujourd'hui";
    } else if (date >= yesterday && date < today) {
      return "Publiée hier";
    } else {
      // Si la date n'est ni aujourd'hui ni hier, on affiche le jour, mois et année
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "long" }); // Mois en toutes lettres
      const year = date.getFullYear();
      return `Publiée le ${day} ${month} ${year}`;
    }
  };

  const whatsappMessage = (
    num: string,
    name: string,
    titre: string,
    type: string
  ): void => {
    const url = `https://wa.me/${num}?text=Bonjour ${name}, je suis intéressé(e) par votre ${titre} en ${type} sur le site d'annonce www.emohup.fr`;

    window.open(url, "_blank");
  };

  const nextSlide = (valu: number) => {
    if (currentIndex !== valu - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  // Fonction pour passer à l'image précédente
  const prevSlide = (valu: number) => {
    if (currentIndex === 0) {
      setCurrentIndex(valu - 1); // Va à la dernière image
    } else {
      setCurrentIndex((prevIndex) => prevIndex - 1); // Sinon, décrémente l'index
    }
  };

  const getAnnonce = async () => {
    setSearch(true);
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}annonces/one/${oneAnnonce}`,
        withCredentials: true,
      });
      if (res.data) {
        setSearch(false);
        setAnnonce([res.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAnnonce();
  }, []);
  return (
    <StyledPopOneAnnonce onClick={() => setOneAnnonce("")}>
      {search ? (
        <BtnLoader />
      ) : (
        <div className="box-all" onClick={(e) => e.stopPropagation()}>
          {annonce ? (
            annonce.map((ann, index) => (
              <div
                className="border-radius"
                onClick={(e) => e.stopPropagation()}
                key={index}
              >
                <div
                  className="slider-to-img"
                  onClick={(e) => e.stopPropagation()}
                >
                  {ann.images && ann.images.length > 0 ? (
                    <div className="box-to-img">
                      {ann.images.length > 1 && (
                        <MdOutlineNavigateBefore
                          className="icon-change"
                          onClick={() => prevSlide(ann.images.length)}
                        />
                      )}
                      <img
                        src={ann.images[currentIndex]}
                        alt="img-annocne"
                        className="animate__animated animate__zoomIn"
                      />
                      {ann.images.length > 1 && (
                        <MdOutlineNavigateNext
                          className="icon-change"
                          onClick={() => nextSlide(ann.images.length)}
                        />
                      )}
                    </div>
                  ) : (
                    <MdImageNotSupported className="noImg" />
                  )}
                </div>
                <div
                  className="annonce-selected"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h1>{ann.titre}</h1>
                  <div className="details">
                    <span>
                      <IoIosInformationCircleOutline />
                      {ann.type}
                    </span>
                    <span>
                      <IoGitCommitSharp />
                      {ann.categorie}
                    </span>
                    <span>
                      <IoLocationOutline />
                      {ann.ville}
                    </span>
                  </div>
                  <p className="description">{ann.description}</p>
                  <div className="contact">
                    <span
                      className="span-whatsapp"
                      onClick={() =>
                        whatsappMessage(
                          ann.numero,
                          ann.pseudo,
                          ann.titre,
                          ann.type
                        )
                      }
                    >
                      <IoLogoWhatsapp className="i whatsap" />
                      Whatsapp
                    </span>
                    <span
                      className="span-appel"
                      onClick={() =>
                        (window.location.href = `tel:+${ann.numero}`)
                      }
                    >
                      <FaPhoneFlip className="i appel" />
                      Appel
                    </span>
                  </div>
                  <div className="sp-profil">
                    <span> {vertDate(ann.createdAt)}</span>
                    <strong>
                      <CgProfile className="i-profil" />
                      {ann.pseudo}
                    </strong>
                  </div>
                  <div className="float-price-point">
                    <span className="price">{ann.price} €</span>
                    <span className="info-point">
                      {ann.point === "true" ? "+1 pt" : "0 pt"}
                    </span>
                  </div>
                </div>
                {partenaires.length > 0 && <TwoAds />}
              </div>
            ))
          ) : (
            <strong className="introuvable">Annonce introuvable</strong>
          )}
        </div>
      )}
    </StyledPopOneAnnonce>
  );
};

export default PopOneAnnonce;

const StyledPopOneAnnonce = styled.div`
  /* position: absolute; */
  position: fixed;
  z-index: 45;
  background: #2929287c;
  /* background: red; */
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 0px 10px;
  animation: slide-up 0.3s ease-out forwards;
  .introuvable {
    background: ${COLORS.white};
  }
  .box-all {
    display: flex;
    background: ${COLORS.blueLow};
    padding: 5px;
    display: block;
    width: 70%;
    height: 100%;
    margin: 20px;
    border-radius: 70px 70px 0px 0px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    .border-radius {
      /* background: pink; */
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .slider-to-img {
        width: 55%;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        .box-to-img {
          display: flex;
          justify-content: center;
          align-items: center;
          .icon-change {
            font-size: 2em;
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            padding: 5px;
            margin: 0px 10px;
            border: none;
            cursor: pointer;
            border-radius: 50%;
            transition: background-color 0.3s ease;
          }
          .icon-change:hover {
            background-color: rgba(0, 0, 0, 0.8);
          }
          img {
            width: 70%;
            border-radius: 5px;
            transition: transform 0.5s ease-in-out;
          }
        }
        .noImg {
          font-size: 15em;
          display: flex;
          margin: 0px auto;
          align-self: center;
          color: ${COLORS.gold};
          border-radius: 10px;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
        }
      }
      .annonce-selected {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30%;
        /* background: blue; */
        position: relative;
        /* height: 100%; */
        padding-top: 50px;
        .float-price-point {
          position: absolute;
          width: 100%;
          z-index: 50;
          display: flex;
          align-items: center;
          justify-content: space-between;
          top: 0px;
          right: 0px;
          background: ${COLORS.greenDark};
          padding: 5px;
          border-radius: 10px;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
          .price {
            font-size: 1.5em;
            color: white;
          }
          .info-point {
            font-size: 1em !important;
            background: ${COLORS.gold};
            /* margin-top: 5px; */
            border-radius: 5px;
            padding: 5px;
          }
        }
        h1 {
          text-align: center;
          font-size: 2em;
          margin: 20px 0px;
        }
        .details {
          width: 100%;
          border-radius: 5px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          background: pink;
          span {
            display: flex;
            align-items: center;
          }
        }
        .description {
          margin: 15px 0px;
        }
        .sp-profil {
          margin-top: 10px;
          display: flex;
          align-items: center;
          span {
            font-size: 0.7em;
          }
          strong {
            display: flex;
            align-items: center;
            .i-profil {
              margin-left: 10px;
            }
          }
        }

        .contact {
          margin: 0px auto;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          border-bottom: solid 3px ${COLORS.green};
          /* border-radius: 10px; */
          /* animation: waveEffect 4s infinite ease-in-out; */
          /* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); */
          .span-whatsapp,
          .span-appel {
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            border-radius: 20px;
            margin-bottom: 20px;
            cursor: pointer;
            font-size: 1.1em;
            letter-spacing: 0.7em;
            /* color: ${COLORS.black}; */
            color: white;
            background: ${COLORS.green};
            border-bottom: solid 5px ${COLORS.green};
            .whatsap {
              font-size: 4.3em;
              /* color: ${COLORS.green}; */
              color: white;
              padding: 5px;
            }
            .appel {
              color: white;
              padding: 5px;
              font-size: 3.8em;
            }
          }
        }
        @keyframes waveBorder {
          0% {
            border-radius: 37% 63% 20% 80% / 78% 49% 51% 22%;
          }
          25% {
            border-radius: 50% 50% 30% 70% / 60% 70% 30% 40%;
            box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
          }
          50% {
            border-radius: 40% 60% 40% 60% / 50% 50% 60% 40%;
          }
          75% {
            border-radius: 30% 70% 50% 50% / 70% 30% 40% 60%;
          }
          100% {
            border-radius: 37% 63% 20% 80% / 78% 49% 51% 22%;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
          }
        }
        @keyframes waveEffect {
          0% {
            border-radius: 10px 20px 10px 20px; /* Forme de départ */
          }
          25% {
            border-radius: 20px 10px 20px 10px; /* Transition douce */
          }
          50% {
            border-radius: 30px 15px 30px 15px; /* Forme plus arrondie */
          }
          75% {
            border-radius: 15px 30px 15px 30px; /* Retour progressif */
          }
          100% {
            border-radius: 10px 20px 10px 20px; /* Retour à la forme initiale */
          }
        }
      }
    }
  }
  @keyframes slide-up {
    from {
      transform: translateY(100%); /* Commence en dehors de la vue */
    }
    to {
      transform: translateY(0); /* Arrive à sa position normale */
    }
  }

  @media screen and (max-width: 450px) {
    position: fixed;
    padding: 0px;
    height: 100vh;
    /* overflow-y: scroll; */
    .box-all {
      height: 75vh !important;
      padding: 5px !important;
      border-radius: 40px 40px 0px 0px;
      width: 100%;
      margin: 5px;
      /* height: 90% !important; */
      /* overflow-y: scroll; */
      .border-radius {
        height: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;
        overflow-y: scroll;
        padding-top: 10px;
        width: 100%;
        padding-bottom: 50px;
        .slider-to-img {
          margin: 0px;
          padding: 0px;
          width: 100%;
          .icon-change {
            font-size: 1.5em !important;
            padding: 0px;
          }
          img {
            display: block;
            width: 100%;
          }
        }
        .annonce-selected {
          width: 100%;
          h1 {
            font-size: 1.5em;
            margin: 10px 0px !important;
          }
          .details {
            margin-top: 5px;
            span {
              font-size: 0.8em;
            }
          }
          .description {
            font-size: 0.8em;
          }
          .contact {
            width: 100%;
            padding: 0px 60px;
            .span-whatsapp,
            .span-appel {
              .whatsap {
                font-size: 3.3em;
              }
              .appel {
                font-size: 3em;
              }
            }
          }
          .sp-profil {
            width: 100%;
            justify-content: space-around;
          }
          .float-price-point {
            width: auto !important;
            .price {
              margin-right: 10px;
              font-size: 1.1em;
            }
          }
        }
      }
    }

    @keyframes slide-up {
      from {
        transform: translateY(100%); /* Commence en dehors de la vue */
      }
      to {
        transform: translateY(0%); /* Arrive à sa position normale */
      }
    }
  }
`;
