import React from "react";
import styled from "styled-components";
import { COLORS } from "../../COLORS";
import BtnLoader from "./BtnLoader";

const PopUp = ({
  setCookieCheck,
}: {
  setCookieCheck: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const accept = () => {
    const date = new Date();
    date.setTime(date.getTime() + 90 * 24 * 60 * 60 * 1000); // Convertir les jours en millisecondes
    const expires = "expires=" + date.toUTCString();
    document.cookie =
      "acceptEmohup.fr02" +
      "=" +
      "okacceptEmohup.fr02" +
      ";" +
      expires +
      ";path=/";
    window.location.reload();
  };
  const redirectToGoogle = () => {
    window.location.href = "https://www.google.com";
  };
  return (
    <StyledPopUp>
      <div className="info-accept">
        <BtnLoader />
        <p>
          En naviguant sur Emoh Up, vous acceptez nos{" "}
          <span>conditions d'utilisation</span>
          et notre politique de <span>confidentialité</span>.
        </p>
        <button onClick={() => accept()}>J'accepte</button>
        <button onClick={() => redirectToGoogle()} className="refuse">
          Je refuse
        </button>
      </div>
    </StyledPopUp>
  );
};

export default PopUp;
const StyledPopUp = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 40;
  background: #292928c2;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 60%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(50%, -70%);
  border-radius: 10px;
  .info-accept {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    p {
      color: ${COLORS.white};
      margin: 10px 0px;
      background: ${COLORS.black};
      padding: 5px;
      border-radius: 5px;
      span {
        color: ${COLORS.green};
        margin: 0px 5px;
      }
    }
    button {
      border: none;
      background: ${COLORS.green};
      padding: 10px;
      border-radius: 10px;
      color: ${COLORS.white};
      font-size: 2em;
      margin-top: 20px;
      cursor: pointer;
    }
    .refuse {
      background: ${COLORS.black};
      color: ${COLORS.green};
    }
  }
  @media screen and (max-width: 429px) {
    height: 50vh;
    width: 90%;
    position: fixed;
    transform: translate(50%, -50%);
    align-items: baseline;
    .info-accept {
      button {
        font-size: 1.5em;
      }
    }
  }
`;
