import React, { useState } from "react";
import { StyledLabel } from "../Post/Forms/Annonce/EtapeOne";
import styled from "styled-components";
type TypeProp = {
  selectedCity: string;
  setSelectedCity: React.Dispatch<React.SetStateAction<string>>;
};
const ListVilleUsable = ({ selectedCity, setSelectedCity }: TypeProp) => {
  const [city, setCity] = useState([
    { value: "Cayenne", label: "Cayenne" },
    { value: "Matoury", label: "Matoury" },
    { value: "Remire", label: "Remire" },
    { value: "Roura", label: "Roura" },
    { value: "Macouria", label: "Macouria" },
    { value: "St-Georges", label: "St-Georges" },
    { value: "Kourou", label: "Kourou" },
    { value: "Sinnamary", label: "Sinnamary" },
    { value: "Iracoubo", label: "Iracoubo" },
    { value: "Mana", label: "Mana" },
    { value: "Awala", label: "Awala" },
    { value: "St-Laurent", label: "St-Laurent" },
    { value: "Apatou", label: "Apatou" },
  ]);
  const handleChose = (value: string) => {
    setSelectedCity(selectedCity === value ? "" : value); // Toggle sélection/décochage
  };
  return (
    <StyledListVille>
      {city &&
        city.length > 0 &&
        city.map((cit, index) => (
          <StyledLabel key={index} $isSelected={selectedCity === cit.value}>
            <input
              key={cit.value + selectedCity}
              type="checkbox"
              value={cit.value}
              checked={selectedCity === cit.value}
              onChange={() => handleChose(cit.value)}
            />
            {cit.value}
          </StyledLabel>
        ))}
    </StyledListVille>
  );
};

export default ListVilleUsable;

const StyledListVille = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
