import React from "react";
import styled from "styled-components";
import { COLORS } from "../../COLORS";
import { vertDate } from "../Utils/Fonctions";
type TypeBusinessA = {
  createdAt: string;
  description: string;
  pseudo: string;
  numero: string;
  statut: number;
  ville: string;
  id: string;
};
const LocalBusiness = ({
  createdAt,
  description,
  pseudo,
  numero,
  statut,
  ville,
  id,
}: TypeBusinessA) => {
  const whatsappMessage = (numero: string): void => {
    const url = `https://wa.me/${numero}?text=Bonjour, je suis intéressé(e) par votre annonce business vu sur le site d'annonce www.emohup.fr`;

    window.open(url, "_blank");
  };
  return (
    <StyledLocal className="animate__animated animate__bounceIn">
      <h2>{pseudo}</h2>
      <span>Ville : {ville}</span>
      <p>{description}</p>
      <div className="btn-action">
        <strong onClick={() => (window.location.href = `tel:+${numero}`)}>
          Appel
        </strong>
        <strong onClick={() => whatsappMessage(numero)}>Whatsapp</strong>
      </div>
      <span>{vertDate(createdAt)}</span>
    </StyledLocal>
  );
};

export default LocalBusiness;
const StyledLocal = styled.div`
  padding: 15px;
  border-radius: 35px;
  background: ${COLORS.green};
  height: 50vh;
  max-width: 350px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  h2 {
    text-align: center;
    color: white;
  }
  span {
    font-size: 0.8em;
    font-family: Arial, Helvetica, sans-serif;
  }
  p {
    width: 100%;
    margin-top: 15px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: white;
  }
  .btn-action {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    padding: 5px;
    background: white;
    border-radius: 10px;
    strong {
      margin: 10px;
      padding: 5px 25px;
      cursor: pointer;
      color: ${COLORS.greenDark};
      border-radius: 10px;
      background: ${COLORS.purple};
      transition: 0.3s;
      box-shadow: 1px 1px 5px ${COLORS.greenDark};
    }
    strong:hover {
      transform: scale(1.1);
      box-shadow: 1px 1px 10px ${COLORS.greenDark};
    }
  }
`;
